<script lang="ts" setup>
type Link = {
  title: string
  to: string
}

const props = defineProps<{
  links: Link[]
}>()

const mainLink = { title: 'Главная', to: '/' }

const getLinks = computed<Link[]>(() => [mainLink, ...props.links])
</script>

<template>
  <div class="hidden items-center md:flex md:py-6 lg:py-8">
    <template v-for="(link, index) in getLinks" :key="index">
      <NuxtLink class="text-black" :to="link.to">
        {{ link.title }}
      </NuxtLink>

      <div class="mx-2">
        <SvgIcon v-if="getLinks.length !== index + 1" name="outlined/arrow-right" />
      </div>
    </template>
  </div>
</template>
